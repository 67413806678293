import { Turbo, cable } from '@hotwired/turbo-rails';
import "@rails/actiontext";

import Quicky from '../src/admin/quicky';
import RealAdmin from '../src/admin/real_admin';
import ModelosDeContato from '../src/admin/modelos_de_contato';
import AfiliadoModelosDeContato from '../src/afs/afiliado_modelos_de_contato';

import '../src/admin/sidebar';
import "../channels/consumer";
import '../src/admin/dashboards';
import '../src/thirds/copy_field';
import '../src/thirds/angular/angular';
import '../src/vendas_eventos/angular_app';
import '../src/thirds/angular/angular-sanitize';

$(document).on('turbo:load', () => {
  new Quicky();
  new RealAdmin();

  if(window.location.pathname.includes('afs')){
    new AfiliadoModelosDeContato();
  }else{
    new ModelosDeContato();
  }
});
