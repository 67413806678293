var app = angular.module('dashboards', ['ngSanitize']);

app.controller('dashboardsCtrl', ['$scope', '$http', dashboard])
function dashboard($s, $http) {
  $s.loading = false;
  $s.tarefas = [];
  $s.tarefaAtiva = null;

  $s.showTarefa = function(t){ $s.tarefaAtiva = t; }
  $s.viajante = function(vo){
    var v = {};
    $s.tarefaAtiva.viajantes.forEach(function(vj, i){
      if(vj.id == vo.vendas_viajante_id) v = $s.tarefaAtiva.viajantes[i];
    });
    return v;
  }
  $s.check = function(t){
    url = "/tarefas/realizada/" + t.id;
    t.realizado = !t.realizado;
    $http({method: 'GET', url: url}).then($s.nothing, $s.erro);
  }

  $s.busca = function(){
    $s.loading = true;
    $http({method: 'GET', url: '/dashboards.json'}).then($s.loadData, $s.erro);
  }

  $s.nothing = function(res){ console.log(res); }
  $s.loadData = function(res){$s.tarefas = res.data; $s.loading = false;}
  $s.erro = function(err){ window.alert(err); $s.loading = false; }

  $s.busca();
  setInterval($s.busca, 30000);
}

