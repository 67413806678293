(($) => {
  const URL = "/vendas_eventos/";
  const app = angular.module('eventos', []);

  app.controller('eventosCtrl', ['$scope', '$http', function($s, $http) {
    $s.filterLastEvent = null;
    $s.results = [];
    // ------------- LISTING
    $s.rand = function(){ return '&rnd=' + Math.random().toString(36).substr(2); };
    $s.filters = function(){
      return "?format=json" + $s.rand();
    }
    $s.getList = function(){
      $http({ method: 'GET', url: URL + $s.filters() }).then(
        function(response, i) {$s.results = response.data;},
        function(response) {}
      );
    }
  
    // ------------- ACTIONS
    $s.adicionarNf = function(evt){
      alert("Adicionar nota ao evento: " + evt.id);
    }
    $s.resolve = function(venda_id){
      $http({ method: 'PUT', url: "/vendas/" + venda_id + "?format=js", data: {faturando: false} }).then(
        function(response, i) {$s.resolveRemove(venda_id);},
        function(response) {}
      );
    }
    $s.resolveRemove = function(venda_id){
      for(var i=0; i < $s.results.length; i++) {
        if($s.results[i].venda_id == venda_id){
          $s.results.splice(i, 1);
        }
      }
    }
  
    // Start
    $s.getList();
  }]);
})(jQuery);