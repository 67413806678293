export default class Quicky {
  constructor() {
    $('.quicky-toggle').on('click', this.toggle)
    $('.quicky-text').on('keyup', this.text)
  }

  toggle(evt){
    let token = jQuery("meta[name='csrf-token']").attr("content");
    evt.preventDefault();
    let el = $(evt.target)[0];

    $.ajax({
      url: el.href,
      type: "post",
      data: "value=" + el.dataset.value + "&token=" + token,
      success: function(res) {
        let b = Boolean(parseInt(el.dataset.value));
        console.log("B", b);
        $(el).fadeOut(250, function(){
          $(el).html(b ? el.dataset.true : el.dataset.false);
          console.log("inverte b", b ? 0 : 1);
          el.dataset.value = b ? 0 : 1;
          $(el).fadeIn(250);
        });
      },
      error: function(res, err) { window.alert(err) }
    });
  }

  text(evt){
    let token = jQuery("meta[name='csrf-token']").attr("content");
    evt.preventDefault();
    let el = $(evt.target)[0];
    if(evt.key != 'Enter') return;

    $.ajax({
      url: el.dataset.url,
      type: "post",
      data: "value=" + el.value + "&token=" + token,
      success: function(res) { $(el).fadeOut(250).fadeIn(250); },
      error: function(res, err) { window.alert(err) }
    });
  }
}

module.exports = Quicky;
