export default class AfiliadoModelosDeContato {
  constructor() {
    this.select = document.getElementById('input-datalist-select');
    this.dataList = document.getElementById('modelos-list');
    this.input_title = document.getElementById('contato_titulo');
    this.input_message = document.getElementById('contato_mensagem');

    this.#handleSelectionChange();
  }

  #handleSelectionChange() {
    this.select?.addEventListener("change", (event) => {
      const selectedOption = this.dataList.querySelector(`option[data-title="${event.target.value}"]`);
      const body = selectedOption.dataset.body;
      const title = selectedOption.dataset.title;

      this.#changeContentEditor(title, body);
    });
  }

  #changeContentEditor(title, body){
    this.input_title.value = title;
    window.setNewsEditorContent(body);
  }
};